import BrV from 'br-validations'

export default {
  mask() {
    return '##.###.###/####-##'
  },
  validate(v) {
    if (v) return BrV.cnpj.validate(v)
    return false
  },
}

<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="CNPJ"
          v-model="company.cnpj"
          :rules="rules.cnpj"
          v-mask="mask.cnpj"
          required
          @change="saveCnpj"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="pt-2">
        <span>Tem Certificado Digital e-CNPJ A1 ou A3?</span>
        <base-bullet-info
          href="https://simplificador.com.br/blog/o-que-e-certificado-digital/"
        ></base-bullet-info>
        <v-radio-group
          v-model="company.hasDigitalCertificate"
          :rules="rules.hasDigitalCertificate"
          @change="saveHasDigitalCertificate"
          required
          row
        >
          <v-radio label="Sim" value="yes"></v-radio>
          <v-radio label="Não" value="no"></v-radio>
          <v-radio label="Não sei informar" value="dont_know"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="senhaWeb"
          type="password"
          append-icon="lock"
          label="Senha Web Prefeitura"
          v-model="company.senhaWebPrefeitura"
          required
          :rules="rules.senhaWebPrefeitura"
          @change="senhaWebPrefeitura"
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="k-bullet">
        <base-bullet-info
          class="k-bullet-left"
          href="https://simplificador.com.br/blog/esqueci-minha-senha-web/"
        ></base-bullet-info>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import cnpj from '@src/services/cnpj'
import InputFile from '@src/components/widget/InputFile.vue'

import { companyMethods, companyComputed } from '@state/helpers'

export default {
  name: 'company-transformation',
  components: {
    InputFile,
  },
  mounted() {},
  data() {
    return {
      rules: {
        contratoSocial: [
          (v) => {
            if (this.partners.length < 2) return true
            return !!v || 'Envie o seu contrato social'
          },
        ],
        cnpj: [
          (v) => !!v || 'Por favor coloque o CNPJ.',
          (v) => cnpj.validate(v) || 'CNPJ inválido.',
        ],
        senhaWebPrefeitura: [(v) => !!v || 'Por favor coloque a senha web'],
        hasDigitalCertificate: [
          (v) => !!v || 'Por favor escolha uma das opções',
        ],
      },
      mask: {
        cnpj: cnpj.mask(),
      },
    }
  },
  computed: {
    ...companyComputed,
    ...mapGetters({
      partners: 'partners',
    }),
    hasSocialContractHint() {
      let hint =
        'Caso não possua enviaremos pelo correio um contrato social padrão nos modelos da Junta Comercial ou Cartório sem custo.'
      return this.company.hasSocialContract ? '' : hint
    },
  },
  methods: {
    ...companyMethods,
    saveCnpj(v) {
      this.sendToApi('cnpj', v)
    },
    saveContratoSocial(file) {
      this.$store.dispatch(
        'taxonomy/saveCompanyContratoSocialConstituicao',
        file
      )
    },
    saveHasSocialContract(v) {
      this.sendToApi('hasSocialContract', v)
    },
    saveHasDigitalCertificate(v) {
      this.sendToApi('hasDigitalCertificate', v)
    },
    senhaWebPrefeitura(v) {
      this.sendToApi('senhaWebPrefeitura', v)
    },
    sendToApi(fild, value) {
      let data = { [fild]: value }
      this.updateCompany(data)
    },
  },
}
</script>
<style lang="sass">
.application
  .container
    .k-bullet
      padding-top: 15px

.k-bullet-left
  position: absolute
  margin-top: 5px
  margin-left: 5px
</style>

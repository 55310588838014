<template>
  <v-file-input
    @change="localValue = $event"
    :value="file"
    v-bind="$attrs"
    prepend-icon=""
    persistent-hint
    :prepend-inner-icon="$attrs['prepend-icon'] || 'attach_file'"
  >
    <template v-slot:selection="{ text }">
      <v-chip small label v-if="showLabelSend">
        {{ $attrs['label-send'] }}
      </v-chip>
      <span v-else> {{ text }}</span>
    </template>
  </v-file-input>
</template>
<script>
export default {
  props: ['value'],
  data() {
    return {
      file: typeof this.value === 'number' ? {} : null,
    }
  },

  computed: {
    localValue: {
      get() {
        return typeof this.value === 'number' ? {} : this.file
      },
      set(newValue) {
        this.file = newValue
        if (
          typeof newValue === 'object' &&
          newValue.constructor.name === 'File'
        ) {
          const reader = new FileReader()
          reader.onloadend = (e) => {
            const fileData = e.target.result
            const file64 = fileData.replace(/^data:.+;base64,/, '')
            this.$emit('input', file64)
            this.$emit('change', file64)
          }
          reader.readAsDataURL(newValue)
        }
      },
    },
    showLabelSend() {
      if (typeof this.value === 'number') this.file = {}
      return typeof this.value === 'number'
    },
  },
}
</script>
